import React from 'react';
import { useAccountBookContext } from './components/AccountBookContext';
import { Card } from '../../components';
import { bulkDebitBillingType as bulkDebitBillingTypeMap } from './staticData';
import BatchPaymentOrderBillingPage from './BatchPaymentOrderBillingPage';
import { BatchPaymentTabs } from '@icoach/accountBook/components';
import BatchPaymentOrdersByCreditCard from '@icoach/accountBook/BatchPaymentOrdersByCreditCard';
import BatchPaymentOrdersByCash from '@icoach/accountBook/BatchPaymentOrdersByCash';

const CurrentOrdersContent = () => {
    const { tableData } = useAccountBookContext();
    const { creditItems } = tableData || {};

    return <BatchPaymentOrdersByCreditCard tableData={creditItems} />;
};

const ExemptedOrdersContent = () => {
    const { tableData } = useAccountBookContext();
    const { noPaymentItems = [] } = tableData || {};

    return <BatchPaymentOrdersByCash tableData={noPaymentItems} />;
};

// 欠費名單
const OverdueOrdersContent = () => {
    const { tableData } = useAccountBookContext();
    const { creditUnpaidItems } = tableData || {};
    return <BatchPaymentOrdersByCreditCard tableData={creditUnpaidItems} />;
};

const BatchPaymentOrderRentCabinetPage = (props) => {
    const { paramData } = useAccountBookContext();
    const { bulkDebitBillingType } = paramData;
    
    return (
        <Card className={'tabs-content-layout'}>
            <BatchPaymentTabs />
            <Card.CardContent className={'p-4'}>
                {bulkDebitBillingType === bulkDebitBillingTypeMap.billing && <BatchPaymentOrderBillingPage />}
                {bulkDebitBillingType === bulkDebitBillingTypeMap.currentOrders && <CurrentOrdersContent />}
                {bulkDebitBillingType === bulkDebitBillingTypeMap.exemptedOrders && <ExemptedOrdersContent />}
                {bulkDebitBillingType === bulkDebitBillingTypeMap.overdueOrders && <OverdueOrdersContent />}
            </Card.CardContent>
        </Card>
    );
};

export default BatchPaymentOrderRentCabinetPage;
